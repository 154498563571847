import uuid from 'uuid/v1'
import { connect, FormikContextType } from 'formik'

import LinkedQuestion from './linkedQuestion'
import { IconAdd, IconMove, IconDelete, IconMoveUp, IconMoveDown } from '../icons'
import { QuestionType, Target } from './question'
import { BlockType } from '../check/block'
import React, { useEffect } from 'react'
import RichEditor, { emptyRichText } from '../form/richEditor'
import Strings from '../strings'

interface Props {
  blocks: BlockType[]
}

export type Conditions = {
  conditions: Condition[]
}

export type Condition = {
  uid: string
  description: string
  hint: string
  info: string
} & Target

const QuestionCondition: React.FunctionComponent<Props & { formik: FormikContextType<QuestionType<Conditions>> }> = ({
  formik,
  blocks,
}) => {
  const { values, setFieldValue } = formik
  const { logic } = values

  useEffect(() => {
    if (!logic.hasOwnProperty('conditions')) {
      setFieldValue('logic', {
        conditions: [],
        ...logic,
      } as Conditions)
    }
  }, [])

  const { conditions } = logic

  const handleAddClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault()

    const condition: Condition = {
      uid: uuid(),
      description: emptyRichText,
      hint: emptyRichText,
      info: emptyRichText,
      link: null,
      end: null,
    }
    setFieldValue('logic.conditions' as any, conditions.concat([condition]))
  }

  const removeCondition = uid => {
    if (confirm(Strings.de.components.check.confirmDeleteOption)) {
      setFieldValue('logic', { ...logic, conditions: conditions.filter(condition => condition.uid !== uid) })
    }
  }

  function _swap(obj, prop1, prop2) {
    var tmp = obj[prop1];
    obj[prop1] = obj[prop2];
    obj[prop2] = tmp;
  }  

  const moveOptionUp = (index, uid) => {
    if (index > 0) {
      _swap(conditions, index, index - 1);
    }
    setFieldValue('logic', { ...logic, conditions: conditions });
    const elem: HTMLElement | null = document.getElementById(uid)!;
    elem.scrollIntoView({ block: 'start', behavior: 'smooth' });
  }

  const moveOptionDown = (index, uid) => {
    if (index < conditions.length - 1) {
      _swap(conditions, index, index + 1);
    }
    setFieldValue('logic', { ...logic, conditions: conditions });
    const elem: HTMLElement | null = document.getElementById(uid)!;
    elem.scrollIntoView({ block: 'start', behavior: 'smooth' });
  }

  return (
    <>
      {conditions &&
        conditions.map((condition, index) => {
          return (
              <div key={condition.uid} id={condition.uid} className="flex cm--question-condition">
              <div className="w=1/12 my=2 flex align-top justify-end p=2 cm--block-actions">
                <IconMoveUp className="c=prim-alt pointer ml=1" onClick={() => moveOptionUp(index, condition.uid)} />
                <IconMoveDown className="c=prim-alt pointer ml=1" onClick={() => moveOptionDown(index, condition.uid)} />
                <IconDelete className="c=prim-alt pointer ml=1" onClick={() => removeCondition(condition.uid)} />                
              </div>
              <details className="my=2 b w=full" open>
                <summary className="w=full flex align-start p=2 arrow-down c=prim">
                  <span className="flex align-center">
                    {Strings.de.components.question.condition} {index + 1}{' '}
                  </span>
                </summary>
                <div className="w=full">
                  <RichEditor
                    placeholder={Strings.de.components.question.condition}
                    name={`logic.conditions[${index}].description`}
                    value={logic.conditions[index].description}
                    onChange={setFieldValue}
                  />
                  <details className="w=full mb=1">
                    <summary className="arrow-down p=1 pointer c=additional">
                      {Strings.de.components.question.hint}
                    </summary>
                    <RichEditor
                      className="c=additional-alt"
                      placeholder={Strings.de.components.question.enterHint}
                      name={`logic.conditions[${index}].info`}
                      value={logic.conditions[index].info}
                      onChange={setFieldValue}
                    />
                  </details>
                  <details className="mb=1 w=full">
                    <summary className="arrow-down c=sec p=1 pointer">{Strings.de.components.question.output}</summary>
                    <RichEditor
                      placeholder={Strings.de.components.question.enterOutput}
                      name={`logic.conditions[${index}].hint`}
                      value={logic.conditions[index].hint}
                      onChange={setFieldValue}
                    />
                  </details>
                  <LinkedQuestion className="w=full" name="conditions" index={index} blocks={blocks} />
                </div>
              </details>
            </div>
          )
        })}
      <button className="w=full mb=2 flex align-center c=text justify-end cm--btn-add-option" onClick={handleAddClick}>
        <IconAdd className="mr=1" /> {Strings.de.components.question.addOption}
      </button>
      <style jsx>{`
        .b {
          border-color: #45a582;
        }
      `}</style>
    </>
  )
}

export default connect<Props, QuestionType<Conditions>>(QuestionCondition)
