import React from 'react'
import { connect, FormikContextType } from 'formik'

import { QuestionType, QuestionTypes } from './question'
import dynamic from 'next/dynamic'
import Toggle from '../form/toggle'

const RichEditor = dynamic(() => import('../form/richEditor'), {
  ssr: false,
})

interface Props {
  name: string
  value: string
  isEnd: boolean
  cb: () => void
}

const EndTextEdit: React.FunctionComponent<Props & {
  formik: FormikContextType<QuestionType<QuestionTypes>>
}> = props => {
  const { formik, name, value, isEnd, cb } = props
  const { setFieldValue } = formik

  return (
    <React.Fragment>
      <div className="w=full c=sec">
        <Toggle checked={isEnd} onChange={cb} label="Ende mit Endtext" />
      </div>
      {isEnd && <RichEditor name={name} placeholder="Text eingeben*" value={value} onChange={setFieldValue} />}
    </React.Fragment>
  )
}

export default connect<Props, QuestionType<QuestionTypes>>(EndTextEdit)
