import React from 'react'
import { IconDelete, IconEdit, IconMove, IconCopy } from './icons'

const Edit = props => {
  const { editable, focus, uid, edit, deletable, handleDelete, handleMove, handleCopy } = props

  if (!props.children) {
    return null
  }

  let elements = React.Children.toArray(props.children)

  if (elements.length === 1) {
    elements = React.cloneElement(elements[0], { edit })
  } else if (elements.length > 0) {
    const lastElement = elements[elements.length - 1]
    elements = [React.cloneElement(elements[0], { edit })]
      .concat(elements.slice(1, -1))
      .concat(React.cloneElement(lastElement, { edit }))
  }

  const handleEdit = () => {
    if (!edit) {
      focus(uid)
    }
  }

  return (
    <div className={`${edit ? '' : editable ? 'edit' : 'non-edit'} r px=1 py=3 mr=1 my=1 cm--block-edit`} tabIndex="0">
      <div className="edit__buttons">
        <button className="p=1 transparent" onClick={handleEdit}>
          <IconEdit />
        </button>
        {deletable && (
          <>
            <button
              className="p=1 transparent c=prim-alt"
              onClick={() => {
                handleMove(uid)
              }}
            >
              <IconMove />
            </button>
            <button className="p=1 transparent c=prim-alt" onClick={handleCopy}>
              <IconCopy />
            </button>
            <button className="p=1 transparent c=prim-alt" onClick={handleDelete}>
              <IconDelete />
            </button>
          </>
        )}
      </div>

      {elements}

      <style jsx>{`
        .edit__buttons {
          display: none;
          z-index: 9;
        }
        .transparent {
          background-color: transparent;
        }
        .edit {
          position: relative;
          border: 1px solid white;
        }
        .edit .edit__buttons {
          display: none;
          position: absolute;
          right: 0;
          top: 0;
          transform: translateY(-50%);
        }
        .non-edit {
          opacity: 0.7;
        }
        .edit:hover,
        .edit:focus {
          border: 1px solid #d9dbda;
          outline: 0;
        }
        .edit:hover .edit__buttons,
        .edit:focus .edit__buttons {
          display: block;
        }
      `}</style>
    </div>
  )
}

export default Edit
