import React, { useState, useEffect } from 'react'
import Link from 'next/link'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Router from 'next/router'

import Toolbar from '../toolbar'
import { updateCheck, copyCheck, deleteCheck, getChecks, Check } from '../../utils/checks'
import { WithAdminSyncProps } from '../../utils/auth'
import { IconAdd, IconSearch } from '../icons'
import Pagination from '../table/pagination'
import LoadingSpinner from '../loadingSpinner'
import Toggle from '../form/toggle'
import Strings from '../strings'
import ActionSelect from '../table/actionSelect'
import SortBy from '../table/sortBy'

interface Props extends WithAdminSyncProps {
  checks: Check[]
  page: number
  perPage: number
  totalRecords: number
  categoryId?: string
}

const CheckTable: React.FunctionComponent<Props> = props => {
  const { token, categoryId } = props
  const [checks, setChecks] = useState(props.checks)
  const [page, setPage] = useState(props.page)
  const [perPage, setPerPage] = useState(props.perPage)
  const [totalRecords, setTotalRecords] = useState(props.totalRecords)
  const [searchValue, setSearchValue] = useState('')
  const [refresh, setRefresh] = useState(false)
  const [categories, setCategories] = useState(categoryId ? [categoryId] : [])
  const [isSubmitting, setSubmitting] = useState(false)
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc')
  const [sortBy, setSortBy] = useState<'type' | 'title' | 'customTitle' | 'creator' | 'createdAt'>('createdAt')

  const doRefresh = () => setRefresh(!refresh)
  const stopSubmitting = () => setSubmitting(false)

  const setResponseStates = ({ perPage, page, data: checks, totalRecords }) => {
    setPerPage(perPage)
    setPage(page)
    setChecks(checks)
    setTotalRecords(totalRecords)
  }

  useEffect(() => {
    setCategories(categoryId ? [categoryId] : [])
  }, [categoryId])

  useEffect(() => {
    setPage(0)
    doRefresh()
  }, [searchValue])

  useEffect(() => {
    setSubmitting(true)
    if (!categories.length) {
      getChecks(token, page, perPage, { searchValue, sortBy, sortDirection })
        .then(setResponseStates)
        .finally(stopSubmitting)
    } else {
      getChecks(token, page, perPage, { searchValue, categoryIds: categories.join(), sortBy, sortDirection })
        .then(setResponseStates)
        .finally(stopSubmitting)
    }
  }, [page, perPage, totalRecords, refresh, categories, sortBy, sortDirection])

  async function handleActivation(event: React.ChangeEvent<HTMLInputElement>, id: string, valid: boolean) {
    const nowChecked = event.target.checked
    event.preventDefault()
    setSubmitting(true)

    if (valid || !nowChecked) {
      if (
        confirm(
          nowChecked ? Strings.de.components.check.confirmActivation : Strings.de.components.check.confirmDeactivation,
        )
      ) {
        updateCheck(token, id, { active: nowChecked })
          .then(doRefresh)
          .finally(stopSubmitting)
      }
    } else {
      alert(Strings.de.components.check.notLinked)
    }
  }

  async function handleAction(event: React.ChangeEvent<HTMLSelectElement>, id: string, token: string) {
    const action = event.target.value
    event.target.selectedIndex = 0
    setSubmitting(true)

    if (action === 'edit') {
      Router.push('/admin/checks/edit/[id]', `/admin/checks/edit/${id}`)
    } else if (action === 'delete') {
      if (confirm(Strings.de.components.check.confirmDeleteCheck)) {
        deleteCheck(token, id)
          .then(doRefresh)
          .finally(stopSubmitting)
      }
    } else if (action === 'copy') {
      copyCheck(token, id)
        .then(doRefresh)
        .finally(stopSubmitting)
    }
  }

  const hasToken = !(token === '')

  const handlePageJump = (e, id) => {
    if (e.target === e.currentTarget) {
      Router.push(`/admin/checks/edit/[id]`, `/admin/checks/edit/${id}`)
    }
  }

  return (
    <React.Fragment>
      <Toolbar>
        <div className="w=1/2 md:w=1/4 bb flex align-center cm--toolbar-search-wrapper">
          <label htmlFor="search">
            <IconSearch className="ml=3" />
          </label>
          <input
            id="search"
            type="search"
            placeholder={Strings.de.components.check.searchChecks}
            value={searchValue}
            onChange={event => setSearchValue(event.target.value)}
            className="py=3 px=2 c=text w=full"
          ></input>
        </div>
        <Link href="/admin/checks/add">
          <a className="text-s ml px=2 flex align-center cm--toolbar-btn">
            <IconAdd className="mr=1" />
            {Strings.de.components.check.addCheck}
          </a>
        </Link>
      </Toolbar>
      <div className="table__container table__container--wide">
        {isSubmitting && <LoadingSpinner fullOverlay={true} />}
        <Table classes={{ root: 'c=text r elevated table table--wide' }}>
          <TableHead>
            <TableRow>
              <TableCell>{Strings.de.components.check.state}</TableCell>
              <TableCell>
                {Strings.de.components.check.typeShort}{' '}
                <SortBy sort={'type'} sortBy={sortBy} setSortBy={setSortBy} setDirection={setSortDirection} />
              </TableCell>
              <TableCell>
                {Strings.de.components.check.name}{' '}
                <SortBy sort={'title'} sortBy={sortBy} setSortBy={setSortBy} setDirection={setSortDirection} />
              </TableCell>
              <TableCell className="sticky right c=text">{Strings.de.components.table.actions}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="table__body">
            {checks &&
              checks.map(check => {
                if (!check) {
                  return
                }

                const { id, title, active, valid, pdfLabels } = check

                return (
                  <TableRow key={id} className="hover:c=sec pointer">
                    <TableCell
                      component="th"
                      scope="row"
                      onClick={e => {
                        handlePageJump(e, id)
                      }}
                    >
                      <div className="flex align-center">
                        <Toggle
                          checked={active && valid}
                          onChange={event => {
                            handleActivation(event, id, valid)
                          }}
                        />
                        {active ? (
                          <span className="text-s bold">{Strings.de.components.check.active}</span>
                        ) : (
                          <span className="light italic text-s"> {Strings.de.components.check.inactive}</span>
                        )}
                      </div>
                    </TableCell>
                    <TableCell
                      onClick={e => {
                        handlePageJump(e, id)
                      }}
                    >
                      {pdfLabels.title}
                    </TableCell>
                    <TableCell
                      onClick={e => {
                        handlePageJump(e, id)
                      }}
                    >
                      {title}
                    </TableCell>
                    {hasToken && (
                      <TableCell className="sticky right c=text">
                        <ActionSelect id={id} token={token} onChange={handleAction} duplicate={true} />
                      </TableCell>
                    )}
                  </TableRow>
                )
              })}
            <Pagination
              count={totalRecords}
              rowsPerPage={perPage}
              page={page}
              onChangePage={(event, newPage) => setPage(newPage)}
              onChangeRowsPerPage={event => setPerPage(parseInt(event.target.value))}
            />
          </TableBody>
        </Table>
      </div>
      <style jsx>{`
        .opacity {
          opacity: 0.4;
        }
      `}</style>
    </React.Fragment>
  )
}

export default CheckTable
