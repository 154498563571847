import React, { useState, useEffect } from 'react'
import { Formik, Form, FormikHelpers } from 'formik'

import Renderer from '../form/richEditorRenderer'
import { QuestionCondition, QuestionFormula, QuestionYesNo, QuestionSwitch, QuestionsStatus } from '.'
import { getQuestionTypeDisplayName, validateCheckLinks, getQuestionPosition } from '../../utils/checks'
import { BlockType } from '../check/block'
import { YesNo } from './questionYesNo'
import { Formula } from './questionFormula'
import { Conditions } from './questionCondition'
import dynamic from 'next/dynamic'
import { isEmptyRichText } from '../form/richEditor'
import Strings from '../strings'

const RichEditor = dynamic(() => import('../form/richEditor'), {
  ssr: false,
})

interface Props {
  edit?: boolean
  question: QuestionType<QuestionTypes>
  questionIndex: number
  blocks: BlockType[]
  blockIndex: number
  saveQuestion: (index: number, question: QuestionType<QuestionTypes>) => void
  cancelQuestion: () => void
  validateBlock: (index: number, valid: boolean) => void
}

export type QuestionType<T> = {
  uid: string
  type: string
  title: string
  description: string
  logic: T
}

export type QuestionTypes = YesNo | Formula | Conditions

export type Target = {
  link: string | null
  end: string | null
}

type Errors = {
  title?: string
  description?: string
  logic?: {
    yes?: EndString
    no?: EndString
    eq?: EndString
    gt?: EndString
    lt?: EndString
    conditions?: EndString[]
  }
}
type EndString = {
  end?: string | null
}
export const target = { end: null, link: null }

const Question: React.FunctionComponent<Props> = ({
  edit,
  question,
  questionIndex,
  blocks,
  blockIndex,
  saveQuestion,
  cancelQuestion,
  validateBlock,
}) => {
  const [questionType, setQuestionType] = useState(question.type)
  const [valid, setValid] = useState(validateCheckLinks(question, blocks))
  const questionTypeDisplayName = getQuestionTypeDisplayName(questionType, blockIndex, questionIndex)

  useEffect(() => {
    validateBlock(questionIndex, valid)
  }, [valid, question])

  useEffect(() => {
    setValid(validateCheckLinks(question, blocks))
  }, [blocks])

  const questionComponent = (type: string) => {
    type QuestionProps<T> = {
      question: QuestionType<T>
      blocks: BlockType[]
      questionTypeDisplayName: string
    }

    const questionProps: QuestionProps<QuestionTypes> = {
      question,
      blocks,
      questionTypeDisplayName,
    }

    switch (type) {
      case 'condition':
        return <QuestionCondition {...(questionProps as QuestionProps<Conditions>)} />
      case 'formula':
        return <QuestionFormula {...(questionProps as QuestionProps<Formula>)} />
      default:
        return <QuestionYesNo {...(questionProps as QuestionProps<YesNo>)} />
    }
  }

  const validateYesNoEnd = (values: QuestionType<YesNo>, errors: Errors) => {
    const tempErrors = errors
    if (values.logic.yes && values.logic.yes.end && isEmptyRichText(values.logic.yes.end)) {
      if (!tempErrors.hasOwnProperty('logic')) tempErrors.logic = {}
      tempErrors.logic!.yes = {}
      tempErrors.logic!.yes.end = 'Bitte Endtext eingeben'
    }
    if (values.logic.no && values.logic.no.end && isEmptyRichText(values.logic.no.end)) {
      if (!tempErrors.hasOwnProperty('logic')) tempErrors.logic = {}
      tempErrors.logic!.no = {}
      tempErrors.logic!.no.end = 'Bitte Endtext eingeben'
    }
    return tempErrors
  }

  const validateConditionEnd = (values: QuestionType<Conditions>, errors: Errors) => {
    const tempErrors = errors
    tempErrors.logic = {
      conditions: [],
    }
    if (values.logic.conditions) {
      tempErrors.logic.conditions = values.logic.conditions.map(el => {
        if (el.end && isEmptyRichText(el.end)) {
          return { end: 'Bitte Endtext einfügen' }
        }
        return { end: null }
      })

      if (tempErrors.logic.conditions.every(el => el.end === null)) {
        delete tempErrors.logic
      }
    }
    return tempErrors
  }

  const validateFormulaEnd = (values: QuestionType<Formula>, errors: Errors) => {
    const tempErrors = errors
    if (values.logic.eq && values.logic.eq.end && isEmptyRichText(values.logic.eq.end)) {
      if (!tempErrors.hasOwnProperty('logic')) tempErrors.logic = {}
      tempErrors.logic!.eq = {}
      tempErrors.logic!.eq.end = 'Bitte Endtext eingeben'
    }

    if (values.logic.gt && values.logic.gt.end && isEmptyRichText(values.logic.gt.end)) {
      if (!tempErrors.hasOwnProperty('logic')) tempErrors.logic = {}
      tempErrors.logic!.gt = {}
      tempErrors.logic!.gt.end = 'Bitte Endtext eingeben'
    }

    if (values.logic.lt && values.logic.lt.end && isEmptyRichText(values.logic.lt.end)) {
      if (!tempErrors.hasOwnProperty('logic')) tempErrors.logic = {}
      tempErrors.logic!.lt = {}
      tempErrors.logic!.lt.end = 'Bitte Endtext eingeben'
    }
    return tempErrors
  }

  const handleSubmit = async (
    values: QuestionType<QuestionTypes>,
    actions: FormikHelpers<QuestionType<QuestionTypes>>,
  ) => {
    setValid(validateCheckLinks(values, blocks))
    saveQuestion(questionIndex, { ...values, uid: question.uid })
    actions.setSubmitting(false)
  }

  const validate = (values: QuestionType<QuestionTypes>) => {
    let errors = {} as Errors

    if (values.type === 'yesNo') {
      errors = validateYesNoEnd(values as QuestionType<YesNo>, errors)
    }

    if (values.type === 'condition') {
      errors = validateConditionEnd(values as QuestionType<Conditions>, errors)
    }

    if (values.type === 'formula') {
      errors = validateFormulaEnd(values as QuestionType<Formula>, errors)
    }

    if (isEmptyRichText(values.title)) {
      errors.title = 'Frage fehlt'
    }
    return errors
  }

  if (!edit) {
    return (
      <React.Fragment>
        <hr className="mt=1 mx=-1" style={{ borderColor: '#bbbbbb' }} />
        <div className="question-label flex align-center">
          <span className="c=prim inline-block py=1 px=2 mb=1 r">{questionTypeDisplayName}</span>
          {question && <QuestionsStatus linked={valid} />}
        </div>
        <Renderer raw={question.title}></Renderer>
        <style jsx>{`
          .question-label {
            transform: translateY(-50%);
          }
        `}</style>
      </React.Fragment>
    )
  }
  return (
    <Formik initialValues={question} onSubmit={handleSubmit} validate={validate}>
      {({ values, isSubmitting, setFieldValue }) => (
        <Form className="mt=3">
          <hr className="mx=-1" style={{ borderColor: '#bbbbbb' }} />
          <div className="question-label flex align-center">
            <span className="c=prim inline-block p=1 mb=1 r">{questionTypeDisplayName}</span>
            {question && <QuestionsStatus linked={valid} />}
          </div>
          <fieldset>
            <legend className="c=prim inline-block px=1 mb=1 r">{Strings.de.components.question.question}</legend>
            <RichEditor name="title" placeholder="Frage eingeben*" value={values.title} onChange={setFieldValue} />

            <details className="mb=2">
              <summary className="arrow-down bb c=additional p=1 pointer">
                {Strings.de.components.question.hint}
              </summary>
              <RichEditor
                className="c=additional-alt"
                name="description"
                placeholder={`${Strings.de.components.question.enterHint}*`}
                value={values.description}
                onChange={setFieldValue}
              />
            </details>
          </fieldset>

          {/* <QuestionSwitch type={questionType} cb={setQuestionType} /> */}
          {questionComponent(questionType)}
          <div className="flex justify-end mt=3 mb=5">
            <button
              onClick={event => {
                event.preventDefault()
                cancelQuestion()
              }}
              className="c=text p=1 cm--btn-cancel"
            >
              {Strings.de.components.question.cancel}
            </button>
            <button type="submit" className="c=prim ml=1 r-circle py=1 px=2 cm--btn-save" disabled={isSubmitting}>
              {Strings.de.components.question.save}
            </button>
          </div>
          <style jsx>{`
            .question-label {
              transform: translateY(-50%);
            }
          `}</style>
        </Form>
      )}
    </Formik>
  )
}

export default Question
