import React, { useState, useEffect } from 'react'
import { Field, connect, FieldArray, FormikContextType } from 'formik'

import { Meta } from '../check/checkMetaForm'
import { getCategories, Category } from '../../utils/categories'
import Strings from '../strings'

import { isLoggedIn, isAdmin } from '../../utils/auth'

const CategorySelect: React.FunctionComponent<{ formik: FormikContextType<Meta>; multiple?: boolean; token?: string }> = ({
  formik,
  multiple = false,
  token = false,
}) => {
  const { values = {}, setFieldValue } = formik
  const { categories = [] } = values as Meta
  const user = isLoggedIn(token)
  const userIsAdmin = isAdmin(token)

  const [data, setData] = useState<Category[]>([])
  useEffect(() => {
    getCategories().then(setData)
  }, [])

  const [unCollapsed, setUnCollapsed] = useState<string[]>([])

  const renderCategory = (category: Category, arrayHelpers) => {
    const hasChildren = category.children.length > 0
    const isPrivate = category.isPrivate

    if (isPrivate && !userIsAdmin) {
      return
    }

    const toggleCollapse = id => {
      const tempUnCollapsed = [...unCollapsed]

      if (unCollapsed.indexOf(id) >= 0) {
        setUnCollapsed(
          tempUnCollapsed.filter(el => {
            return el !== id
          }),
        )
      } else {
        setUnCollapsed(tempUnCollapsed.concat(id))
      }
    }

    const isCollapsed = id => {
      return !(unCollapsed.indexOf(id) >= 0)
    }

    return (
      <div key={category.id} className={'flex column c=text'}>
        <div
          className={`flex p=2 w=full pointer relative align-center ${hasChildren && 'arrow-down bb'} ${
            !multiple && categories[0] === category.id ? 'c=sec' : ''
          }`}
        >
          <label className="w=11/12 pointer">
            <Field
              type={multiple ? 'checkbox' : 'radio'}
              value={category.id}
              name="categories"
              className={!multiple ? 'hidden' : 'w=1/12'}
              checked={Array.from(categories).indexOf(category.id) >= 0}
              onChange={e => {
                if (e.target.checked) {
                  if (multiple) {
                    setFieldValue('categories', [...categories, category.id])
                  } else {
                    setFieldValue('categories', [category.id])
                  }
                } else {
                  const idx = Array.from(categories).indexOf(category.id)
                  const temp = [...categories]
                  temp.splice(idx, 1)
                  setFieldValue('categories', temp)
                }
              }}
            />

            {category.name}
            {isPrivate && (
              <span> [P]</span>
            )}
          </label>
          {hasChildren && (
            <span
              className=" p=3 w=1/4 h=full absolute right top bottom pointer"
              onClick={() => {
                toggleCollapse(category.id)
              }}
            ></span>
          )}
        </div>
        {hasChildren && (
          <div className={`pl=1 ${isCollapsed(category.id) && 'hidden'}`}>
            {category.children.map(category => renderCategory(category, arrayHelpers))}
          </div>
        )}
        <style jsx>{`
          .bb {
            border-color: #ebecec;
          }
        `}</style>
      </div>
    )
  }

  return (
    <React.Fragment>
      {!multiple && (
        <div className="flex p=2 w=full pointer relative align-center">
          <label className="w=11/12 pointer">
            <Field
              type="radio"
              value={categories}
              name="categories"
              className="hidden"
              checked={categories === []}
              onChange={e => {
                if (e.target.checked) {
                  setFieldValue('categories', [])
                }
              }}
            />
            {Strings.de.components.form.allCategories}
          </label>
        </div>
      )}
      <FieldArray
        name="categories"
        render={arrayHelpers => data.map(category => renderCategory(category, arrayHelpers))}
      />
    </React.Fragment>
  )
}

export default connect<{ multiple?: boolean, token?: string, }, Meta>(CategorySelect)
